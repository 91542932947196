import SubcategoryIcon from '@material-ui/icons/List';

import SubcategoryEdit from './SubcategoryEdit';
import SubcategoryCreate from './SubcategoryCreate';

const subcategories = {
    edit: SubcategoryEdit,
    create: SubcategoryCreate,
    icon: SubcategoryIcon,
};

export default subcategories;
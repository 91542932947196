import {
    List, Datagrid, TextField, EditButton, DateField, ReferenceField,
    NumberField, Filter, TextInput, ReferenceInput, SelectInput,
} from 'react-admin';

const ExpenseFilter = (props) => (
    <Filter {...props}>
        <TextInput source="description" alwaysOn />
        <ReferenceInput
            source="category_id"
            reference="categories"
            filter={{ type: "expense" }}
        >
            <SelectInput optionText="name" />
        </ReferenceInput>

        {(props.filterValues.category_id || props.filterValues.subcategory_id) &&
            <ReferenceInput
                key={props.filterValues.category_id}
                source="subcategory_id"
                reference="subcategories"
                perPage={200}
                filter={{ category_id: props.filterValues.category_id }}
            >
                <SelectInput optionText="name" />
            </ReferenceInput>}

        <ReferenceInput
            source="account_from_id"
            reference="accounts"
            filter={{ is_active: true }}
        >
            <SelectInput optionText="name" />
        </ReferenceInput>

        <ReferenceInput
            source="currency_out_id"
            reference="currencies"
        >
            <SelectInput optionText="code" />
        </ReferenceInput>
    </Filter>
);


const ExpenseList = (props) => (
    <List {...props} sort={{ field: 'date', order: 'DESC' }} filters={<ExpenseFilter />} >
        <Datagrid>
            {/* <TextField source="id" /> */}
            <DateField source="date" />
            <ReferenceField source="category_id" reference="categories" link={false}>
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField source="subcategory_id" reference="subcategories" link={false}>
                <TextField source="name" />
            </ReferenceField>

            <ReferenceField source="account_from_id" reference="accounts" link={false}>
                <TextField source="name" />
            </ReferenceField>
            <NumberField source="amount_out" />
            <ReferenceField source="currency_out_id" reference="currencies" link={false}>
                <TextField source="code" />
            </ReferenceField>

            <TextField source="description" />
            <EditButton basePath="/expenses" />
        </Datagrid>
    </List>
);

export default ExpenseList;
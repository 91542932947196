import { FormDataConsumer, required } from 'react-admin';
import MyAutoCompleteInput from './MyAutoCompleteInput';

const CategoryInput = (props) => {
    return (
        <>
            <MyAutoCompleteInput
                source="category_id"
                label="Category"
                choices={props.categories}
                field="name"
                validate={required()}
            />
            <FormDataConsumer>
                {({formData, ...rest}) => {
                    let loaded = (props.subcategories !== undefined) && (formData.category_id > 0)
                    let choices = []
                    if (loaded) {
                        choices = props.subcategories.filter(record => record.category_id === formData.category_id);
                    }
                    return (
                        <MyAutoCompleteInput
                            source="subcategory_id"
                            label="Subategory"
                            choices={choices}
                            field="name"
                            validate={required()}
                        />
                    );
                }}
            </FormDataConsumer>
        </>
    );
};

export default CategoryInput;
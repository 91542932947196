import { Create, SimpleForm, TextInput, BooleanInput } from 'react-admin';

const AccountCreate = (props) => (
    <Create title="Create an account" {...props}>
        <SimpleForm>
            <TextInput source="name" />
            <TextInput source="description"/>
            <BooleanInput source="is_active" />
        </SimpleForm>
    </Create>
);

export default AccountCreate;
import { DateInput, NumberInput, TextInput, required } from 'react-admin';

import MyAutoCompleteInput from '../components/MyAutoCompleteInput';
import CategoryInput from '../components/CategoryInput';

const IncomeFormContent = ({data}) => (
    <>
        <DateInput source="date" validate={required()} />
        <CategoryInput categories={data.categories} subcategories={data.subcategories} />
        <MyAutoCompleteInput source="account_to_id" label="Account" choices={data.accounts} field="name" validate={required()} />
        <NumberInput source="amount_in" validate={required()} />
        <MyAutoCompleteInput source="currency_in_id" label="Currency" choices={data.currencies} field="code" validate={required()} />
        <TextInput source="description" />
    </>
);

export default IncomeFormContent;
import IncomeIcon from '@material-ui/icons/MoveToInbox';

import IncomeList from './IncomeList';
import IncomeEdit from './IncomeEdit';
import IncomeCreate from './IncomeCreate';

const incomes = {
    list: IncomeList,
    edit: IncomeEdit,
    create: IncomeCreate,
    icon: IncomeIcon,
};

export default incomes;
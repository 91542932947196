import {
    Edit, SimpleForm, SelectInput, TextInput, ReferenceInput
} from 'react-admin';


const SubcategoryTitle = ({ record }) => {
    return <span>Subcategory {record ? `"${record.name}"` : ''}</span>;
};

export const SubcategoryEdit = (props) => (
    <Edit title={<SubcategoryTitle />} {...props}>
        <SimpleForm>
            <TextInput disabled source="id" />
            <ReferenceInput disabled source="category_id" reference="categories">
                <SelectInput optionText="name" />
            </ReferenceInput>
            <TextInput source="name" />
        </SimpleForm>
    </Edit>
);

export default SubcategoryEdit;
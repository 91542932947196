import * as React from "react";
import { useEffect, useState } from "react";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Title, useNotify, useVersion, useDataProvider } from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import { Bar } from 'react-chartjs-2';

import SpendingsByCategories from '../components/SpendingsByCategories';

const LOCALE = 'en-US'


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 0.2,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },

  widgetContent: {
    fontSize: '25px',
  },

  card: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  }
}));

const EomBalancesCard = () => {
    const classes = useStyles();
    const [eomBalances, setEomBalances] = useState([])
    const [loading, setLoading] = useState(true);

    const notify = useNotify();
    const version = useVersion();
    const dataProvider = useDataProvider();

    const parseBalances = (result) => {
        var money = [];
        var stocks = [];
        var labels = [];

        var moneyBgColors = []
        var stocksBgColors = []

        for (var i = 0; i < result.length; i++ ) {
            labels.push(result[i].date)
            money.push(result[i].money)
            stocks.push(result[i].stocks)

            moneyBgColors.push('rgba(129, 199, 132, 0.6)');
            stocksBgColors.push('rgba(100, 181, 246, 0.6)');
        }

        var monthlyBalancesData = {
            labels: labels,
            datasets: [
                {
                    stack: 'Total balance',
                    label: 'Stocks',
                    backgroundColor: stocksBgColors,
                    borderColor: 'rgba(100, 181, 246, 1)',
                    pointHoverBackgroundColor: '#fff',
                    borderWidth: 1,
                    data: stocks,
                },
                {
                    stack: 'Total balance',
                    label: 'Money',
                    backgroundColor: moneyBgColors,
                    borderColor: 'rgba(129, 199, 132, 1)',
                    pointHoverBackgroundColor: '#fff',
                    borderWidth: 1,
                    data: money,
                },
            ],
        };
        setEomBalances(monthlyBalancesData);
    };

    const fetchData = () => {
        setLoading(true);
        dataProvider.getOne('dashboard', {id: 'eom_balances'})
        .then((data) => {
            if (data) {
                parseBalances(data.data.balances);
            }
        })
        .catch(error => {
            notify(error.message, 'error');
        })
        .finally(() => {
            setLoading(false);
        })
    };

    useEffect(()=>{
        fetchData();
    }, [version]); // eslint-disable-line react-hooks/exhaustive-deps

    const mainChartOpts = {
        tooltips: {
          enabled: true,
        //   custom: CustomTooltips,
          intersect: true,
          mode: 'index',
          position: 'nearest',
          callbacks: {
            labelColor: function(tooltipItem, chart) {
              return { backgroundColor: chart.data.datasets[tooltipItem.datasetIndex].borderColor }
            },
            label: function(tooltipItem, chart) {
              return tooltipItem.yLabel.toLocaleString(LOCALE, {maximumFractionDigits: 2});
            },
          }
        },
        maintainAspectRatio: false,
        legend: {
          display: true,
          position: 'bottom',
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                drawOnChartArea: false,
              },
              ticks: {
                callback: function(value, index, values) {
                  return new Date(value).toLocaleString(LOCALE, {month: "short" })
                }
              }
            }],
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                maxTicksLimit: 5,
                stepSize: Math.ceil(250 / 5),
              },
            }],
        },
        elements: {
          point: {
            radius: 0,
            hitRadius: 10,
            hoverRadius: 4,
            hoverBorderWidth: 3,
          },
        },
      };

    return (
        <Card className={classes.card}>
            <CardHeader title="Monthly balances" />
            <CardContent>
                { loading ? <CircularProgress /> :
                    <Bar data={eomBalances} options={mainChartOpts} height={300} />
                }
            </CardContent>
        </Card>
    );
}

const CardWidget = (props) => {
  const classes = useStyles();

  return (
    <Card className={classes.paper}>
      <CardContent>
        {props.header} <br />
        <span className={classes.widgetContent}>
          {
            props.value
            ? props.value.toLocaleString(LOCALE, {maximumFractionDigits: 2, currency: props.currency, style: 'currency'})
            : <CircularProgress size={20}/>
          }
        </span>
      </CardContent>
    </Card>
  );
};

const Dashboard = () => {
  const [baseCurrency, setBaseCurrency] = useState('GBP');
  const [loading, setLoading] = useState(true);
  const [state, setState] = useState({});
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const version = useVersion();

  const fetchData = () => {
    setLoading(true);
    dataProvider.getOne('dashboard', {id: 'summary'})
    .then((data) => {
        if (data) {
            setState((state) => ({
              ...state,
              totalBalance: data.data.totalBalance,
              money: data.data.money,
              spentThisMonth: data.data.spentThisMonth,
            }));
            setBaseCurrency(data.data.baseCurrency);
        }
    })
    .catch(error => {
        notify(error.message, 'error');
    })
    .finally(() => {
        setLoading(false);
    })
  };

  const fetchSpendingsDetailed = () => {
    setLoading(true);
    dataProvider.getOne('dashboard', {id: 'spent_this_month'})
    .then((data) => {
        if (data) {
            setState((state) => ({
              ...state,
              spentThisMonthDetailed: data.data.data.sort((a, b) => {
                let delta = b.spentThisMonth - a.spentThisMonth
                if (delta > 0) {
                  return 1;
                } else if (delta < 0) {
                  return -1;
                }
                return b.spentLastMonthToDate - a.spentLastMonthToDate;
              }),
            }));
        }
    })
    .catch(error => {
        notify(error.message, 'error');
    })
    .finally(() => {
        setLoading(false);
    })
  };

  useEffect(()=>{
    fetchData();
    fetchSpendingsDetailed();
  }, [version]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Title title="Dashboard" />
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs>
            <CardWidget header="Total balance" value={ loading ? null : state.totalBalance } currency={baseCurrency} />
          </Grid>
          <Grid item xs>
            <CardWidget header="Total money" value={ loading ? null : state.money } currency={baseCurrency} />
          </Grid>
          <Grid item xs>
            <CardWidget header="Spent this month" value={ loading ? null: state.spentThisMonth } currency={baseCurrency} />
          </Grid>
        </Grid>
      </div>
      <EomBalancesCard />
      <div className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs>
            <SpendingsByCategories data={loading ? null : state.spentThisMonthDetailed} normalized={false}/>
          </Grid>
          <Grid item xs>
            <SpendingsByCategories data={loading ? null : state.spentThisMonthDetailed} normalized={true}/>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Dashboard;
import ExpenseIcon from '@material-ui/icons/ShoppingCart';

import ExpenseList from './ExpenseList';
import ExpenseEdit from './ExpenseEdit';
import ExpenseCreate from './ExpenseCreate';

const expenses = {
    list: ExpenseList,
    edit: ExpenseEdit,
    create: ExpenseCreate,
    icon: ExpenseIcon,
};

export default expenses;
import { useEffect, useState } from "react";

import {
    List, Datagrid, TextField, EditButton, Filter, BooleanInput, FunctionField,
    useNotify, useVersion, useDataProvider,
} from 'react-admin';

const LOCALE = 'en-US';

const AccountFilter = (props) => (
    <Filter {...props}>
        <BooleanInput source="is_active" alwaysOn />
    </Filter>
);

const renderAccountBalance = (record, loading, accountBalances, code) => {
    if (loading) {
        return '?';
    }
    let balances = {
        [code]: 0,
        ...accountBalances[record.id],
    }
    return balances[code].toLocaleString(LOCALE, { maximumFractionDigits: 2 });
};


const AccountList = (props) => {
    const [baseCurrency, setBaseCurrency] = useState('GBP');
    const [loading, setLoading] = useState(true);
    const [accountBalances, setAccountBalances] = useState({});
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const version = useVersion();

    const fetchAccountBalances = () => {
        setLoading(true);
        dataProvider.getOne('dashboard', { id: 'account_balances' })
            .then((data) => {
                if (data) {
                    setAccountBalances(data.data.data);
                    setBaseCurrency(data.data.baseCurrency)
                }
            })
            .catch(error => {
                notify(error.message, 'error');
            })
            .finally(() => {
                setLoading(false);
            })
    };

    useEffect(() => {
        fetchAccountBalances();
    }, [version]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <List
            {...props}
            filters={<AccountFilter />}
            filterDefaultValues={{ is_active: true }}
        >
            <Datagrid>
                {/* <TextField source="id" /> */}
                <TextField source="name" />
                <TextField source="description" />
                {/* <BooleanField source="is_active" /> */}
                <FunctionField
                    label="GBP"
                    render={record => renderAccountBalance(record, loading, accountBalances, 'GBP')}
                />
                <FunctionField
                    label="USD"
                    render={record => renderAccountBalance(record, loading, accountBalances, 'USD')}
                />
                <FunctionField
                    label="RUB"
                    render={record => renderAccountBalance(record, loading, accountBalances, 'RUB')}
                />
                <FunctionField
                    label="EUR"
                    render={record => renderAccountBalance(record, loading, accountBalances, 'EUR')}
                />
                <FunctionField
                    label="META"
                    render={record => renderAccountBalance(record, loading, accountBalances, 'META')}
                />
                <FunctionField
                    label={"Total (" + baseCurrency + ")"}
                    render={record => renderAccountBalance(record, loading, accountBalances, 'total')}
                />
                <EditButton basePath="/accounts" />
            </Datagrid>
        </List>
    );
};

export default AccountList;
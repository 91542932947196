import { Create, SimpleForm, SelectInput, TextInput } from 'react-admin';

const CategoryCreate = (props) => (
    <Create title="Create a category" {...props}>
        <SimpleForm>
            <SelectInput source="type" choices={[
                { id: 'expense', name: 'Expense' },
                { id: 'income', name: 'Income' },
                { id: 'other', name: 'Other' },
            ]}/>
            <TextInput source="name" />
        </SimpleForm>
    </Create>
);

export default CategoryCreate;
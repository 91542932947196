import {
    Edit, SimpleForm, SelectInput, TextInput, Button, ReferenceManyField,
    Datagrid, TextField, Link, EditButton,
} from 'react-admin';

import AddIcon from '@material-ui/icons/Add';

const CategoryTitle = ({ record }) => {
    return <span>Category {record ? `"${record.name}"` : ''}</span>;
};

const AddSubcategoryButton = ({ record }) => (
    <Button
        variant="raised"
        component={Link}
        to={`/subcategories/create?category_id=${record.id}`}
        label="Add a subcategory"
        title="Add a subcategory"
    >
        <AddIcon />
    </Button>
);

const CategoryEdit = (props) => (
    <Edit title={<CategoryTitle />} {...props}>
        <SimpleForm>
            <TextInput disabled source="id" />
            <SelectInput disabled source="type" choices={[
                { id: 'expense', name: 'Expense' },
                { id: 'income', name: 'Income' },
                { id: 'other', name: 'Other' },
            ]}/>
            <TextInput source="name" />

            <ReferenceManyField
                label="Subcategories"
                reference="subcategories"
                target="category_id"
            >
                <Datagrid>
                    <TextField source="name" />
                    <EditButton />
                </Datagrid>
            </ReferenceManyField>
            <AddSubcategoryButton />
        </SimpleForm>
    </Edit>
);

export default CategoryEdit;
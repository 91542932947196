import { Create, SimpleForm } from 'react-admin';

import IncomeFormContent from './IncomeFormContent';
import useKeyDatasets from '../hooks/useKeyDatasets';

const IncomeCreate = (props) => {
    const data = useKeyDatasets("income");

    return (
        <Create title="Create an income" {...props}>
            <SimpleForm redirect="list">
                <IncomeFormContent data={data}/>
            </SimpleForm>
        </Create>
    );
}

export default IncomeCreate;
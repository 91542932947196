import {
    Create, SimpleForm, DateInput, TextInput, SelectInput
} from 'react-admin';

const LoanCreate = (props) => (
    <Create title="Create a loan" {...props}>
        <SimpleForm>
            <SelectInput source="type" choices={[
                { id: 'issued', name: 'Issued' },
                { id: 'received', name: 'Received' },
            ]} />
            <TextInput source="counterparty" />
            <DateInput source="date_opened" />
            <TextInput source="description" />
        </SimpleForm>
    </Create>
);

export default LoanCreate;
import { Edit, SimpleForm, TextInput } from 'react-admin';

import ExpenseFormContent from './ExpenseFormContent';
import useKeyDatasets from '../hooks/useKeyDatasets';

const ExpenseTitle = ({ record }) => {
    return <span>Edit expense {record ? `"${record.id}"` : ''}</span>;
};

const ExpenseEdit = (props) => {
    const data = useKeyDatasets("expense");

    return (
        <Edit title={<ExpenseTitle />} {...props}>
            <SimpleForm>
                <TextInput disabled source="id" />
                <ExpenseFormContent data={data} />
            </SimpleForm>
        </Edit>
    );
};

export default ExpenseEdit;
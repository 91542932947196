import {
    List, Datagrid, TextField, EditButton, DateField, ReferenceField,
    NumberField, Filter, ReferenceInput, SelectInput,
} from 'react-admin';

const IncomeFilter = (props) => (
    <Filter {...props}>
        <ReferenceInput
            source="category_id"
            reference="categories"
            filter={{type: "income"}}
        >
            <SelectInput optionText="name"/>
        </ReferenceInput>

        {(props.filterValues.category_id || props.filterValues.subcategory_id) &&
        <ReferenceInput
            key={props.filterValues.category_id}
            source="subcategory_id"
            reference="subcategories"
            perPage={200}
            filter={{ category_id: props.filterValues.category_id }}
        >
            <SelectInput optionText="name"/>
        </ReferenceInput>}

        <ReferenceInput
            source="account_to_id"
            reference="accounts"
            filter={{is_active: true}}
        >
            <SelectInput optionText="name"/>
        </ReferenceInput>

        <ReferenceInput
            source="currency_in_id"
            reference="currencies"
        >
            <SelectInput optionText="code"/>
        </ReferenceInput>
    </Filter>
);

const IncomeList = (props) => (
    <List {...props} sort={{ field: 'date', order: 'DESC'}} filters={<IncomeFilter />}>
        <Datagrid>
            {/* <TextField source="id" /> */}
            <DateField source="date" />
            <ReferenceField source="category_id" reference="categories" link={false}>
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField source="subcategory_id" reference="subcategories" link={false}>
                <TextField source="name" />
            </ReferenceField>

            <ReferenceField source="account_to_id" reference="accounts" link={false}>
                <TextField source="name" />
            </ReferenceField>
            <NumberField source="amount_in" />
            <ReferenceField source="currency_in_id" reference="currencies" link={false}>
                <TextField source="code" />
            </ReferenceField>

            <TextField source="description" />
            <EditButton basePath="/incomes" />
        </Datagrid>
    </List>
);

export default IncomeList;
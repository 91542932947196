import { Edit, SimpleForm, TextInput } from 'react-admin';

import IncomeFormContent from './IncomeFormContent';
import useKeyDatasets from '../hooks/useKeyDatasets';

const IncomeTitle = ({ record }) => {
    return <span>Edit income {record ? `"${record.id}"` : ''}</span>;
};

const IncomeEdit = (props) => {
    const data = useKeyDatasets("income");

    return (
        <Edit title={<IncomeTitle />} {...props}>
            <SimpleForm>
                <TextInput disabled source="id" />
                <IncomeFormContent data={data} />
            </SimpleForm>
        </Edit>
    );
};

export default IncomeEdit;
import {
    List, Datagrid, TextField, EditButton, DateField, Filter,
    BooleanInput, BooleanField,
} from 'react-admin';


const LoanFilter = (props) => (
    <Filter {...props}>
        <BooleanInput source="is_active" alwaysOn />
    </Filter>
);

const LoanList = (props) => (
    <List
        {...props}
        filters={<LoanFilter />}
        filterDefaultValues={{ is_active: true }}
        sort={{ field: 'date_opened', order: 'DESC' }}
    >
        <Datagrid>
            <TextField source="id" />
            <TextField source="type" />
            <TextField source="counterparty" />
            <DateField source="date_opened" />
            <BooleanField source="is_active" />
            <TextField source="description" />
            <EditButton basePath="/loans" />
        </Datagrid>
    </List>
);

export default LoanList;
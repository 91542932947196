import CurrencyIcon from '@material-ui/icons/AttachMoney';

import CurrencyList from './CurrencyList';
import CurrencyCreate from './CurrencyCreate';

const currencies = {
    list: CurrencyList,
    create: CurrencyCreate,
    icon: CurrencyIcon,
};

export default currencies;
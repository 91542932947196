import * as React from "react";
import { useEffect, useState } from "react";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Title, useNotify, useVersion, useDataProvider } from 'react-admin';
import TextField from '@material-ui/core/TextField';

import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Bar } from 'react-chartjs-2';

import useKeyDatasets from '../hooks/useKeyDatasets';
import AssessmentIcon from '@material-ui/icons/Assessment';

export const AnalyticsIcon = AssessmentIcon;

const LOCALE = 'en-US'


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 0.2,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },

  widgetContent: {
    fontSize: '25px',
  },

  card: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  }
}));

const ExpensesAnalyticsCard = ({ accountId, categoryId, subcategoryId, currencyType, isExpenses = true }) => {
  const classes = useStyles();
  const [monthlyExpenses, setMonthlyExpenses] = useState([])
  const [loading, setLoading] = useState(true);

  const notify = useNotify();
  const version = useVersion();
  const dataProvider = useDataProvider();

  const parseData = (result) => {
    var amount = [];
    var labels = [];

    var bgColors = []

    for (var i = 0; i < result.length; i++) {
      labels.push(result[i].date)
      amount.push(result[i].amount)

      bgColors.push('rgba(129, 199, 132, 0.6)');
    }

    var monthlyBalancesData = {
      labels: labels,
      datasets: [
        {
          label: 'Total',
          backgroundColor: bgColors,
          borderColor: 'rgba(100, 181, 246, 1)',
          pointHoverBackgroundColor: '#fff',
          borderWidth: 1,
          data: amount,
        },
      ],
    };
    setMonthlyExpenses(monthlyBalancesData);
  };

  const fetchData = (accountId, categoryId, subcategoryId, currencyType) => {
    setLoading(true);
    let accountIdFilter = accountId ? 'account_id=' + accountId : '';
    let categoryIdFilter = categoryId ? 'category_id=' + categoryId : '';
    let subcategoryIdFilter = subcategoryId ? 'subcategory_id=' + subcategoryId : '';
    let currencyTypeFilter = currencyType ? 'currency_type=' + currencyType : '';
    let api_id = isExpenses ? 'monthly_expenses' : 'monthly_incomes';
    dataProvider.getOne('dashboard', { id: api_id + '?' + categoryIdFilter + '&' + subcategoryIdFilter + '&' + currencyTypeFilter + '&' + accountIdFilter },)
      .then((data) => {
        if (data) {
          parseData(data.data.data);
        }
      })
      .catch(error => {
        notify(error.message, 'error');
      })
      .finally(() => {
        setLoading(false);
      })
  };

  useEffect(() => {
    fetchData(accountId, categoryId, subcategoryId, currencyType);
  }, [version, accountId, categoryId, subcategoryId, currencyType]); // eslint-disable-line react-hooks/exhaustive-deps

  const mainChartOpts = {
    tooltips: {
      enabled: true,
      //   custom: CustomTooltips,
      intersect: true,
      mode: 'index',
      position: 'nearest',
      callbacks: {
        labelColor: function (tooltipItem, chart) {
          return { backgroundColor: chart.data.datasets[tooltipItem.datasetIndex].borderColor }
        },
        label: function (tooltipItem, chart) {
          return tooltipItem.yLabel.toLocaleString(LOCALE, { maximumFractionDigits: 2 });
        },
      }
    },
    maintainAspectRatio: false,
    legend: {
      display: true,
      position: 'bottom',
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            drawOnChartArea: false,
          },
          ticks: {
            callback: function (value, index, values) {
              return new Date(value).toLocaleString(LOCALE, { month: "short" })
            }
          }
        }],
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            maxTicksLimit: 5,
            stepSize: Math.ceil(250 / 5),
          },
        }],
    },
    elements: {
      point: {
        radius: 0,
        hitRadius: 10,
        hoverRadius: 4,
        hoverBorderWidth: 3,
      },
    },
  };

  return (
    <Card className={classes.card}>
      <CardHeader title={isExpenses ? "Monthly expenses" : "Monthly incomes"} />
      <CardContent>
        {loading ? <CircularProgress /> :
          <Bar data={monthlyExpenses} options={mainChartOpts} height={300} />
        }
      </CardContent>
    </Card>
  );
}

const AnalyticsComponent = () => {
  const [expenseCategoryId, setExpenseCategoryId] = useState(null);
  const [expenseSubcategoryId, setExpenseSubcategoryId] = useState(null);
  const [incomeCategoryId, setIncomeCategoryId] = useState(null);
  const [incomeSubcategoryId, setIncomeSubcategoryId] = useState(null);
  const [incomeAccountId, setIncomeAccountId] = useState(null);
  const [expenseAccountId, setExpenseAccountId] = useState(null);
  const [currencyType, setCurrencyType] = useState(null);
  const expenseData = useKeyDatasets("expense");
  const incomeData = useKeyDatasets("income");

  return (
    <>
      <Title title="Analytics" />
      <form style={{ display: 'flex' }}>
        {expenseData.accounts &&
          <Autocomplete
            style={{ width: "250px", margin: "5px" }}
            onChange={(event, value) => setExpenseAccountId(value ? value.id : null)}
            options={expenseData.accounts}
            getOptionLabel={(option) => option["name"]}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  label="Account"
                />
              );
            }}
            autoHighlight
          />
        }
        {expenseData.categories &&
          <Autocomplete
            style={{ width: "250px", margin: "5px" }}
            onChange={(event, value) => { setExpenseCategoryId(value ? value.id : null); setExpenseSubcategoryId(null); }}
            options={expenseData.categories}
            getOptionLabel={(option) => option["name"]}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  label="Category"
                />
              );
            }}
            autoHighlight
          />
        }
        {expenseCategoryId &&
          <Autocomplete
            style={{ width: "250px", margin: "5px" }}
            onChange={(event, value) => setExpenseSubcategoryId(value ? value.id : null)}
            options={expenseData.subcategories ? expenseData.subcategories.filter(x => x.category_id === expenseCategoryId) : null}
            getOptionLabel={(option) => option["name"]}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  label="Subcategory"
                />
              );
            }}
            autoHighlight
          />
        }
      </form>
      <ExpensesAnalyticsCard accountId={expenseAccountId} categoryId={expenseCategoryId} subcategoryId={expenseSubcategoryId} isExpenses={true} />
      <form style={{ display: 'flex' }}>
        <Autocomplete
          style={{ width: "250px", margin: "5px" }}
          onChange={(event, value) => setCurrencyType(value)}
          options={['currency', 'stock']}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                label="Currency type"
              />
            );
          }}
          autoHighlight
        />
        {incomeData.accounts &&
          <Autocomplete
            style={{ width: "250px", margin: "5px" }}
            onChange={(event, value) => setIncomeAccountId(value ? value.id : null)}
            options={incomeData.accounts}
            getOptionLabel={(option) => option["name"]}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  label="Account"
                />
              );
            }}
            autoHighlight
          />
        }
        {incomeData.categories &&
          <Autocomplete
            style={{ width: "250px", margin: "5px" }}
            onChange={(event, value) => setIncomeCategoryId(value ? value.id : null)}
            options={incomeData.categories}
            getOptionLabel={(option) => option["name"]}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  label="Category"
                />
              );
            }}
            autoHighlight
          />
        }
        {incomeCategoryId &&
          <Autocomplete
            style={{ width: "250px", margin: "5px" }}
            onChange={(event, value) => setIncomeSubcategoryId(value ? value.id : null)}
            options={incomeData.subcategories ? incomeData.subcategories.filter(x => x.category_id === incomeCategoryId) : null}
            getOptionLabel={(option) => option["name"]}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  label="Subcategory"
                />
              );
            }}
            autoHighlight
          />
        }
      </form>
      <ExpensesAnalyticsCard
        accountId={incomeAccountId}
        categoryId={incomeCategoryId}
        subcategoryId={incomeSubcategoryId}
        currencyType={currencyType}
        isExpenses={false}
      />
    </>
  );
};

export default AnalyticsComponent;
import AccountIcon from '@material-ui/icons/AccountBalanceWallet';

import AccountList from './AccountList';
import AccountEdit from './AccountEdit';
import AccountCreate from './AccountCreate';

const accounts = {
    list: AccountList,
    edit: AccountEdit,
    create: AccountCreate,
    icon: AccountIcon,
};

export default accounts;
import { Edit, SimpleForm, TextInput, BooleanInput } from 'react-admin';

const AccountTitle = ({ record }) => {
    return <span>Account {record ? `"${record.name}"` : ''}</span>;
};

const AccountEdit = (props) => (
    <Edit title={<AccountTitle />} {...props}>
        <SimpleForm>
            <TextInput disabled source="id" />
            <TextInput source="name" />
            <TextInput source="description" />
            <BooleanInput source="is_active" />
        </SimpleForm>
    </Edit>
);

export default AccountEdit;
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CircularProgress from '@material-ui/core/CircularProgress';

import { HorizontalBar } from 'react-chartjs-2';

const LOCALE = 'en-US'

const SpendingsByCategories = ({data, normalized}) => {
    const parseData = (data, normalized) => {
        if (data) {
            if (normalized) {
                return {
                    labels: data.map(record => record.name),
                    datasets: [
                    {
                        label: 'This month',
                        backgroundColor: 'rgba(229,115,115, 0.6)',
                        borderColor: 'rgba(229,115,115, 1)',
                        borderWidth: 1,
                        data: data.map(record => record.spentLastMonthTotal > 0 ? record.spentThisMonth * 1.0 / record.spentLastMonthTotal : 0)
                    },
                    {
                        label: 'Last month (TD)',
                        backgroundColor: 'rgba(100, 181, 246, 0.6)',
                        borderColor: 'rgba(100, 181, 246, 1)',
                        borderWidth: 1,
                        data: data.map(record => record.spentLastMonthTotal > 0 ? record.spentLastMonthToDate * 1.0 / record.spentLastMonthTotal : 0)
                    },
                    {
                        label: 'Last month (Total)',
                        backgroundColor: 'rgba(129, 199, 132, 0.6)',
                        borderColor: 'rgba(129, 199, 132, 1)',
                        borderWidth: 1,
                        data: data.map(record => 0)
                    },
                    ]
                };
            } else {
                return {
                    labels: data.map(record => record.name),
                    datasets: [
                    {
                        label: 'This month',
                        backgroundColor: 'rgba(229,115,115, 0.6)',
                        borderColor: 'rgba(229,115,115, 1)',
                        borderWidth: 1,
                        data: data.map(record => record.spentThisMonth)
                    },
                    {
                        label: 'Last month (TD)',
                        backgroundColor: 'rgba(100, 181, 246, 0.6)',
                        borderColor: 'rgba(100, 181, 246, 1)',
                        borderWidth: 1,
                        data: data.map(record => record.spentLastMonthToDate)
                    },
                    {
                        label: 'Last month (Total)',
                        backgroundColor: 'rgba(129, 199, 132, 0.6)',
                        borderColor: 'rgba(129, 199, 132, 1)',
                        borderWidth: 1,
                        data: data.map(record => record.spentLastMonthTotal)
                    }
                    ]
                };
            }
        }
        return null;
    };

    const chartOptions = {
        showScale: true,
        pointDot: true,
        showLines: false,
        tooltips: {
            callbacks: {
                label: function(tooltipItem, chart) {
                    if (normalized) {
                        return (tooltipItem.xLabel * 100).toLocaleString(LOCALE, {maximumFractionDigits: 0}) + '%';
                    }
                return tooltipItem.xLabel.toLocaleString(LOCALE, {maximumFractionDigits: 2});
                },
            },
        },

        scales: {
          xAxes: [{
              ticks: {
                  beginAtZero:true,
              }
            }]
         }
    }

    const parsedData = parseData(data, normalized);

    return (
        <Card>
            <CardHeader
                title={normalized ? "% of monthly budget" : "This month spendings"}
            />
            <CardContent>
                { parsedData === null ? <CircularProgress /> :
                    <HorizontalBar data={parsedData} height={30 + 20 * parsedData.labels.length} options={chartOptions}/>
                }
            </CardContent>
      </Card>
    );
};

export default SpendingsByCategories;
import { Create, SimpleForm, SelectInput, TextInput } from 'react-admin';

const CurrencyCreate = (props) => (
    <Create title="Create a currency" {...props}>
        <SimpleForm>
            <SelectInput source="type" choices={[
                { id: 'currency', name: 'Currency' },
                { id: 'stock', name: 'Stock' },
            ]} />
            <TextInput source="code" />
        </SimpleForm>
    </Create>
);

export default CurrencyCreate;
import {
    Edit, SimpleForm, DateInput, ReferenceManyField, TextField, ReferenceField,
    Datagrid, TextInput, DateField, SelectInput, BooleanInput, EditButton,
    NumberField,
} from 'react-admin';

const LoanTitle = ({ record }) => {
    return <span>Loan {record ? `"${record.description}"` : ''}</span>;
};

const LoanEdit = (props) => (
    <Edit title={<LoanTitle />} {...props}>
        <SimpleForm>
            <TextInput disabled source="id" />
            <SelectInput disabled source="type" choices={[
                { id: 'issued', name: 'Issued' },
                { id: 'received', name: 'Received' },
            ]} />
            <TextInput source="counterparty" />
            <DateInput source="date_opened" />
            <BooleanInput source="is_active" />
            <TextInput source="description" />

            <ReferenceManyField
                label="Transactions"
                reference="transactions"
                target="loan_id"
            >
                <Datagrid>
                    <DateField source="date" />
                    <ReferenceField source="account_from_id" reference="accounts" link={false}>
                        <TextField source="name" />
                    </ReferenceField>
                    <NumberField source="amount_out" />
                    <ReferenceField source="currency_out_id" reference="currencies" link={false}>
                        <TextField source="code" />
                    </ReferenceField>

                    <ReferenceField source="account_to_id" reference="accounts" link={false}>
                        <TextField source="name" />
                    </ReferenceField>
                    <NumberField source="amount_in" />
                    <ReferenceField source="currency_in_id" reference="currencies" link={false}>
                        <TextField source="code" />
                    </ReferenceField>
                    <EditButton />
                </Datagrid>
            </ReferenceManyField>
        </SimpleForm>
    </Edit>
);

export default LoanEdit;
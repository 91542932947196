import LoanIcon from '@material-ui/icons/AccountBalance';

import LoanList from './LoanList';
import LoanEdit from './LoanEdit';
import LoanCreate from './LoanCreate';

const loans = {
    list: LoanList,
    edit: LoanEdit,
    create: LoanCreate,
    icon: LoanIcon,
};

export default loans;
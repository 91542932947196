import React from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import Admin from './Admin';

import './App.css';

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/" component={Admin} />
      </Switch>
    </Router>
  );
}

export default App;

import { parse } from 'query-string';

import {
    Create, SimpleForm, SelectInput, ReferenceInput, TextInput,
} from 'react-admin';

const SubcategoryCreate = (props) => {
    const { category_id: category_id_string } = parse(props.location.search);
    const category_id = category_id_string ? parseInt(category_id_string, 10) : '';

    const redirect = category_id ? `/categories/${category_id}` : false;

    return (
        <Create title="Create a subcategory" {...props}>
            <SimpleForm defaultValue={{ category_id }} redirect={redirect}>
                <ReferenceInput source="category_id" reference="categories">
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <TextInput source="name" />
            </SimpleForm>
        </Create>
    );
};

export default SubcategoryCreate;
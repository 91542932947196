import {
    List, Datagrid, TextField, EditButton, DateField, ReferenceField,
    NumberField, Filter, ReferenceInput, SelectInput, FunctionField,
} from 'react-admin';

const TransactionFilter = (props) => (
    <Filter {...props}>
        <ReferenceInput
            source="account_to_id"
            reference="accounts"
            filter={{is_active: true}}
        >
            <SelectInput optionText="name"/>
        </ReferenceInput>
        <ReferenceInput
            source="currency_in_id"
            reference="currencies"
        >
            <SelectInput optionText="code"/>
        </ReferenceInput>

        <ReferenceInput
            source="account_from_id"
            reference="accounts"
            filter={{is_active: true}}
        >
            <SelectInput optionText="name"/>
        </ReferenceInput>
        <ReferenceInput
            source="currency_out_id"
            reference="currencies"
        >
            <SelectInput optionText="code"/>
        </ReferenceInput>
    </Filter>
);

const TransactionList = (props) => (
    <List {...props} filter={{category_id: 192}} sort={{ field: 'date', order: 'DESC'}} filters={<TransactionFilter />}>
        <Datagrid>
            {/* <TextField source="id" /> */}
            <DateField source="date" />
            {/* <ReferenceField source="category_id" reference="categories" link={false}>
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField source="subcategory_id" reference="subcategories" link={false}>
                <TextField source="name" />
            </ReferenceField> */}

            <ReferenceField source="account_from_id" reference="accounts" link={false}>
                <TextField source="name" />
            </ReferenceField>
            <NumberField source="amount_out" />
            <ReferenceField source="currency_out_id" reference="currencies" link={false}>
                <TextField source="code" />
            </ReferenceField>

            <ReferenceField source="account_to_id" reference="accounts" link={false}>
                <TextField source="name" />
            </ReferenceField>
            <NumberField source="amount_in" />
            <ReferenceField source="currency_in_id" reference="currencies" link={false}>
                <TextField source="code" />
            </ReferenceField>

            <ReferenceField source="loan_id" reference="loans" link="show">
                <FunctionField
                    label="Loan"
                    render={record => `${record.counterparty} (${record.id})`}
                />
            </ReferenceField>

            <TextField source="description" />
            <EditButton basePath="/transactions" />
        </Datagrid>
    </List>
);

export default TransactionList;
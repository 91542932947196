import React, {
  createContext,
  useState,
  useEffect,
  useCallback,
  useMemo,
  useContext
} from "react";
import {
  SelectInput,
  SimpleForm,
  required,
  useDataProvider,
  useNotify,
  SaveContextProvider,
  useGetIdentity
} from "react-admin";

const ProfileContext = createContext();

export const ProfileProvider = ({ children }) => {
  const [profileVersion, setProfileVersion] = useState(0);
  const context = useMemo(
    () => ({
      profileVersion,
      refreshProfile: () =>
        setProfileVersion((currentVersion) => currentVersion + 1)
    }),
    [profileVersion]
  );

  return (
    <ProfileContext.Provider value={context}>
      {children}
    </ProfileContext.Provider>
  );
};

export const useProfile = () => useContext(ProfileContext);

const useGetProfile = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [profile, setProfile] = useState(null);

  const dataProvider = useDataProvider();
  useEffect(() => {
    setIsLoading(true);
    dataProvider.getOne('profile', {id: 'some_id'})
      .then((data) => {
        setProfile(data);
      })
      .catch(error => {
        // notify(error.message, 'error');
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [dataProvider, setIsLoading, setProfile]);
  return { isLoading: isLoading, profile: profile };
};

export const ProfileEdit = ({ staticContext, ...props }) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [saving, setSaving] = useState();
  const { refreshProfile } = useProfile();

  const { isLoading, profile } = useGetProfile();

  const handleSave = useCallback(
    (values) => {
      setSaving(true);
      dataProvider.update('profile', {id: 'some_id', data: values})
      .then(
        (data) => {
          setSaving(false);
          refreshProfile();
        }
      );
    },
    [dataProvider, notify, refreshProfile]
  );

  const saveContext = useMemo(
    () => ({
      save: handleSave,
      saving
    }),
    [saving, handleSave]
  );

  if (isLoading) {
    return null;
  }

  return (
    <SaveContextProvider value={saveContext}>
      <SimpleForm save={handleSave} record={profile ? profile.data : {}}>
        <SelectInput
          source="base_currency"
          validate={required()}
          choices={[
            { id: 'USD', name: 'USD' },
            { id: 'GBP', name: 'GBP' },
            { id: 'RUB', name: 'RUB' },
          ]} />
      </SimpleForm>
    </SaveContextProvider>
  );
};

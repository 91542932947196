import TransactionIcon from '@material-ui/icons/SyncAlt';

import TransactionList from './TransactionList';
import TransactionEdit from './TransactionEdit';
import TransactionCreate from './TransactionCreate';

const transactions = {
    list: TransactionList,
    edit: TransactionEdit,
    create: TransactionCreate,
    icon: TransactionIcon,
};

export default transactions;
import { useInput } from 'react-admin';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LinearProgress from '@material-ui/core/LinearProgress';


const MyAutoCompleteInput = (props) => {
    const {
        input: { onChange, value, ...rest },
        meta: { touched, error },
        isRequired
    } = useInput(props);

    const myOnChange = (event, value) => {
        if (value) {
            onChange(value.id);
        } else {
            onChange(null);
        }
    }

    let valueSelected = null;
    if (props.choices && value) {
        valueSelected = props.choices.find(record => record.id === value) || null;
    }
    if (!props.choices) {
        return <LinearProgress style={{width: "250px"}}/>;
    }

    return (
        <Autocomplete
            style={{width: "250px"}}
            onChange={myOnChange}
            options={props.choices}
            getOptionLabel={(option) => option[props.field]}
            renderInput={(params) => {
                return (
                    <TextField
                        {...params}
                        label={props.label}
                        error={!!(touched && error)}
                        helperText={touched && error}
                        {...rest}
                    />
                );
            }}
            autoHighlight
            required={isRequired}
            value={valueSelected}
            // {...rest}
        />
    );
};

export default MyAutoCompleteInput;
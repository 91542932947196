import { useCallback, useEffect, useState } from 'react';
import { useVersion, useDataProvider } from 'react-admin';

const useKeyDatasets = (category_type) => {
    const [state, setState] = useState({});
    const dataProvider = useDataProvider();
    const version = useVersion();
    const fetchCategories = useCallback(async (category_type) => {
        const { data: categories } = await dataProvider.getList(
            'categories',
            {
                filter: { type: category_type },
                sort: { field: 'name', order: 'ASC' },
                pagination: { page: 1, perPage: 50 },
            }
        ) || {};
        setState(state => ({...state, categories: categories}));
    }, [dataProvider]);

    const fetchSubcategories = useCallback(async () => {
        const { data: subcategories } = await dataProvider.getList(
            'subcategories',
            {
                filter: {},
                sort: { field: 'name', order: 'ASC' },
                pagination: { page: 1, perPage: 500 },
            }
        ) || {};
        setState(state => ({...state, subcategories: subcategories}));
    }, [dataProvider]);

    const fetchAccounts = useCallback(async () => {
        const { data: accounts } = await dataProvider.getList(
            'accounts',
            {
                filter: {"is_active": true},
                sort: { field: 'name', order: 'ASC' },
                pagination: { page: 1, perPage: 500 },
            }
        ) || {};
        setState(state => ({...state, accounts: accounts}));
    }, [dataProvider]);

    const fetchCurrencies = useCallback(async () => {
        const { data: currencies } = await dataProvider.getList(
            'currencies',
            {
                filter: {},
                sort: { field: 'code', order: 'ASC' },
                pagination: { page: 1, perPage: 500 },
            }
        ) || {};
        setState(state => ({...state, currencies: currencies}));
    }, [dataProvider]);

    useEffect(() => {
        fetchCategories(category_type);
        fetchSubcategories();
        fetchAccounts();
        fetchCurrencies();
    }, [
        version,
        category_type,
        fetchCategories,
        fetchSubcategories,
        fetchAccounts,
        fetchCurrencies,
    ]);

    return {
        categories: state.categories,
        subcategories: state.subcategories,
        accounts: state.accounts,
        currencies: state.currencies,
    };
};

export default useKeyDatasets;
import {
    List, Datagrid, TextField, Filter, SelectInput, EditButton,
} from 'react-admin';

const CategoryFilter = (props) => (
    <Filter {...props}>
        <SelectInput source="type" choices={[
            { id: 'expense', name: 'Expense' },
            { id: 'income', name: 'Income' },
        ]} initialValue="expense" alwaysOn/>
    </Filter>
);

export const CategoryList = (props) => (
    <List
        {...props}
        filters={<CategoryFilter />}
        filterDefaultValues={{ type: "expense" }}
        sort={{ field: 'name', order: 'ASC' }}
    >
        <Datagrid>
            {/* <TextField source="id" /> */}
            <TextField source="type" />
            <TextField source="name" />
            <EditButton basePath="/categories" />
        </Datagrid>
    </List>
);

export default CategoryList;
import React from "react";
import { fetchUtils, Admin as ReactAdmin, Resource, Layout, AppBar, UserMenu, MenuItemLink } from 'react-admin';
import SettingsIcon from "@material-ui/icons/Settings";
import simpleRestProvider from 'ra-data-simple-rest';
import { Route } from "react-router";

import authProvider from './providers/authProvider';
import Dashboard from './dashboard/Dashboard';
import accounts from './accounts';
import expenses from './expenses';
import incomes from './incomes';
import transactions from './transactions';
import loans from './loans';
import currencies from './currencies';
import categories from './categories';
import subcategories from './subcategories';
import analytics from './analytics';
import { ProfileEdit, ProfileProvider, useProfile } from "./profile/profile";

const httpClient = (url: any, options: any) => {
  if (!options) {
    options = {};
  }
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  const token = localStorage.getItem('token');
  options.headers.set('Authorization', `Bearer ${token}`);

  return fetchUtils.fetchJson(url, options);
};

const addUserProfileOverrides = (dataProvider) => ({
  ...dataProvider,
  getUserProfile() {
    const profile = localStorage.getItem("userProfile");
    if (!profile) {
      return Promise.resolve({ data: {baseCurrecny: 'USD'} });
    }
    const data = JSON.parse(profile);
    return Promise.resolve({ data });
  },
  async updateUserProfile({ data }) {
    localStorage.setItem(
      "userProfile",
      JSON.stringify(data)
    );
    return Promise.resolve({ data });
  }
});

const MyUserMenu = (props) => {
  const { profileVersion } = useProfile();

  return (
    <UserMenu key={profileVersion} {...props}>
      <MenuItemLink
        to="/profile"
        primaryText="Profile"
        leftIcon={<SettingsIcon />}
      />
    </UserMenu>
  );
};


const MyAppBar = props => <AppBar {...props} userMenu={<MyUserMenu />} />;

const dataProvider = addUserProfileOverrides(simpleRestProvider('api/v1', httpClient))

const MyLayout = (props) => (
  <ProfileProvider>
    <Layout {...props}
      appBar={MyAppBar}
    />
  </ProfileProvider>
);

const Admin = () => (
  <ReactAdmin dataProvider={dataProvider} authProvider={authProvider} dashboard={Dashboard} disableTelemetry
    customRoutes={[
      <Route
        key="profile"
        path="/profile"
        render={() => <ProfileEdit />}
      />
    ]}
    layout={MyLayout}
  >
    <Resource name="accounts" {...accounts} />
    <Resource name="expenses" {...expenses} />
    <Resource name="incomes" {...incomes} />
    <Resource name="transactions" {...transactions} />
    <Resource name="loans" {...loans} />
    <Resource name="currencies" {...currencies} />
    <Resource name="categories" {...categories} />
    <Resource name="subcategories" {...subcategories} />
    <Resource name="dashboard" />
    <Resource name="analytics" {...analytics} />
  </ReactAdmin>
);

export default Admin;
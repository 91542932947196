import { Create, SimpleForm } from 'react-admin';

import ExpenseFormContent from './ExpenseFormContent';
import useKeyDatasets from '../hooks/useKeyDatasets';

const ExpenseCreate = (props) => {
    const data = useKeyDatasets("expense");

    return (
        <Create title="Create an expense" {...props}>
            <SimpleForm redirect="list">
                <ExpenseFormContent data={data} />
            </SimpleForm>
        </Create>
    );
};

export default ExpenseCreate;
import {
    Edit, SimpleForm, DateInput, ReferenceInput, AutocompleteInput,
    FormDataConsumer, TextInput, NumberInput,
} from 'react-admin';


const TransactionTitle = ({ record }) => {
    return <span>Edit transaction {record ? `"${record.id}"` : ''}</span>;
};

const TransactionEdit = (props) => (
    <Edit title={<TransactionTitle />} {...props}>
        <SimpleForm>
            <TextInput disabled source="id" />
            <DateInput source="date" />
            <ReferenceInput source="category_id" reference="categories">
                <AutocompleteInput optionText="name" resettable={true} disabled />
            </ReferenceInput>
            <FormDataConsumer>
                {
                    ({ formData, ...rest }) => (
                        <ReferenceInput
                            disabled
                            source="subcategory_id"
                            reference="subcategories"
                            sort={{ field: "name", order: "ASC" }}
                            filter={{ category_id: formData.category_id }}
                            {...rest}
                        >
                            <AutocompleteInput optionText="name" resettable={true} />
                        </ReferenceInput>
                    )
                }
            </FormDataConsumer>

            <ReferenceInput source="account_from_id" reference="accounts" filter={{ is_active: true }}>
                <AutocompleteInput optionText="name" resettable={true} />
            </ReferenceInput>
            <NumberInput source="amount_out" />
            <ReferenceInput source="currency_out_id" reference="currencies">
                <AutocompleteInput optionText="code" resettable={true} />
            </ReferenceInput>

            <ReferenceInput source="account_to_id" reference="accounts" filter={{ is_active: true }}>
                <AutocompleteInput optionText="name" resettable={true} />
            </ReferenceInput>
            <NumberInput source="amount_in" />
            <ReferenceInput source="currency_in_id" reference="currencies">
                <AutocompleteInput optionText="code" resettable={true} />
            </ReferenceInput>

            <ReferenceInput source="loan_id" reference="loans">
                <AutocompleteInput optionText="counterparty" resettable={true} />
            </ReferenceInput>

            <TextInput source="description" />
        </SimpleForm>
    </Edit>
);


export default TransactionEdit;